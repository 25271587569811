export const BUSINESS = {
  XUE_CHENG: 1, // 学成
  SHI_ER_XIANG_SHU: 2, // 十二橡树
  XIAO_XIANG_GUAN: 3, // 小象馆
  QIAN_YI: 5, // 千翼
  XUE_DA_ZAI_XIAN: 6, // 学大在线
  XUE_XIAO: 8, // 学校
  WEN_HUA: 7, // 文化
  XUE_YOU_FANG: 12, // 学有方
  LING_YUE_DU: 9, // 呤阅读
  TIANJIN_XUE_YOU_CHENG: 10, // 天津学有成
  ZHI_JIAO: 11, // 职教
  XUE_YOU_YOU: 13, // 学有优
  JU_XIANG: 15, // 句象
  BYD: 16, // X项目
};
