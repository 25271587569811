import request from '../utils/request';

/**
 * 查询所有主体信息
 * @returns
 */
export const getBusinessList = () => {
  return request.get(`/thirdpart-info/list-index`, {
    disable_token: true,
  });

  // 调试企微登录 https
  // return Promise.resolve({
  //   code: 0,
  //   msg: 'Success',
  //   data: [
  //     {
  //       id: 1,
  //       projectId: 1,
  //       thirdpartCorpId: 'ww67969ef74da54bfb',
  //       thirdpartName: '学大教育',
  //       ticket: '1',
  //       weight: 10,
  //       aid: 1,
  //       appId: 1000109,
  //       appName: '营销系统测试应用',
  //       appSecret: 'AmuOo7RIT5pYm6TCzXw-FtVG4680F5JhczTaLBgI1c0',
  //       appToken: 'DBOKDwqxn5DT2a9aU8pH6cfkIqH',
  //       appAesKey: 'A56XnAPyAAOYXzma9APakabzoqahQO1m0FBLD812QJs',
  //     },
  //   ],
  //   success: true,
  // });
};

/**
 * 登录，code换取accessToken
 * @param {object} data
 * @data
 * @param  {string}   code
 * @returns {object:{accessToken,refreshToken}}
 */
export const oauthLogin = (code, corpId, agentId) => {
  return request.get(`/api/v1.0/code/${code}`, {
    disable_token: true,
    params: {
      appId: corpId,
      agentId,
    },
  });
  // return request.get('https://yapi.xue.com/mock/42/code/%7Bclient-id%7D');
};

/**
 * 发送登录验证码
 * sendLoginCode
 */
export const sendLoginCode = (appId, mobile) => {
  // https://fish.xuedaclass.com/api/v1.0/app/1/external/sms/app-member-messages

  return request.post(`/api/v1.0/app/${appId}/external/sms/app-member-messages`, {
    disable_token: true,
    data: {
      mobile,
    },
  });
};

/**
 * 手机号登录
 * @param {object} data
 * @data
 * @param  {string}   corpId
 * @param  {string}   phone
 * @param  {string}   code
 * @returns {object:{accessToken,refreshToken}}
 */
export const phoneLogin = ({ appId, phone, code }) => {
  return request.get(`/api/v1.0/app/${appId}/phone/${phone}/code/${code}`, {
    disable_token: true,
  });
};

/** 初始化登录密码
 * @param {object} appMemberId
 */
export const initLoginPassword = (appMemberId) => {
  return request.post(`/api/v1.0/app/{app-id}/bees-security/reset-phone-password`, {
    data: {
      appMemberId,
    },
  });
};

/** 修改登录密码
 * @param {object} data
 * @data  {string}   phone
 * @data  {string}   newPassword
 * @data  {string}   verificationCode
 */
export const modifyLoginPassword = (data) => {
  return request.post(`/api/v1.0/app/bees-security/change-phone-password`, {
    data,
  });
};

/** 获取临时登录票据
 * @param {stirng} authorizedAppMemberId 目标成员id
 */
export const getTempTicket = (authorizedAppMemberId) => {
  return request.get(`/api/v1.0/app/{app-id}/bees-security/apply-temporary-security-ticket`, {
    params: {
      authorizedAppMemberId,
    },
  });
};

/** 使用票据登录
 * @param {string} ticket
 */
export const loginByTicket = (ticket) => {
  return request.get(`/api/v1.0/app/{app-id}/ticket`, {
    params: {
      ticket,
    },
  });
};

/** 手机号密码登录
 * @param {string} phone
 * @param {string} password
 */
export const loginByPhonePassword = ({ appId, phone, password, code }) => {
  return request.post(`/api/v1.0/app/${appId}/password`, {
    data: {
      phone,
      password,
      code,
    },
  });
};

/**
 * 登出
 * @returns {result}
 */
export const signout = () => {
  return request.get(`/signout`);
};

/**
 * 人人秀免登
 */
export const rrxLogin = () => {
  return request.get(`/api/1.0/app/{app-id}/rrx/login`, {
    maxRedirects: 0,
  });
};

/**
 * 获取统一登录地址
 */
export const getUnifiedLoginUrl = () => {
  return request.get(`/api/v1.0/authorize/init`);
};

/**
 * open登录
 * @param code
 * @param state
 * @returns
 */
export function loginForOpen(code, state) {
  return request.get(`/api/v1.0/sso/state/${state}/code/${code}`);
}

/**
 * 获取用户信息
 */

export default {
  getBusinessList,
  oauthLogin,
  signout,
};
