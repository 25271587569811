import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { getStorage } from '@/utils/filter';

/**
 * 唯一手机号校验
 * @param {string} phone 手机号
 * @returns {result}
 */
export const onlyCheck = (payload) => {
  return request.get(`api/v1.0/app/${getAppId()}/app-customer-count`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 客户列表
 * @param {string} keyword 姓名/手机号/id
 * @param {string} tagsNames 标签
 * @param {string} intention 意向度
 * @param {string} createDate 创建时间
 * @param {string} all 1:全部，0我的
 * @param {string} pageIndex 页索引
 * @param {string} pageSize 页大小
 * @param {string} orderBy 排序字段
 * @param {string} orderDirection 排序类型
 * @returns {array}
 */
export const getCustomerList = (payload) => {
  // eslint-disable-next-line no-param-reassign
  if (payload?.signStatus === -1) payload.signStatus = undefined;
  return request.post(`/api/v1.0/app/${getAppId()}/list-app-member-customer`, {
    data: payload,
  });
};

/**
 * 新建客户
 * @param {string} name 姓名
 * @param {string} phone 手机号
 * @param {string} address 地址
 * @param {number} allotId 分配的咨询师id
 * @param {number} channelId 一级渠道
 * @param {number} channelId2 二级渠道
 * @param {string} city 市
 * @param {string} cityId 市id
 * @param {string} county 区
 * @param {string} countyId 区id
 * @param {string} creatorMemberName 创建人
 * @param {string} descrition 描述
 * @param {number} grade 年级id
 * @param {string} isClue 线索 1
 * @param {string} province 省
 * @param {string} provinceId 省id
 * @param {number} schoolIntention 辅导意愿
 * @param {string} schoolName 学校名称
 * @param {number} sex 性别
 * @param {number} studentType 学员类型
 * @param {number} telLevel 电话级别二级
 * @param {string} weChatId 微信号
 * @param {string} referenceOaName 转介绍OA     渠道为转介绍时选填
 * @param {string} referenceNsNo 转介绍学员编号
 * @param {string} referenceJob 转介绍员工岗位名
 * @param {string} referenceJobCode 转介绍员工岗位code
 * @returns {object}
 */
export const addCustomer = (payload) => {
  if (payload?.referenceOaName) {
    payload.referenceOaName = payload.referenceOaName.trim();
  }
  return request.post(`/api/v1.0/app/${getAppId()}/app-customers`, {
    data: {
      creatorMemberName: getStorage('username'),
      ...payload,
    },
  });
};

/**
 * 查询客户详情
 * @param {number} id 线索id
 * @returns
 */
export const getCustomerInfo = (id) => {
  return request.get(`/api/v1.0/app/${getAppId()}/region/app-customer/${id}`);
};

/**
 * 编辑客户
 * @param {string} name 姓名
 * @param {string} phone 手机号
 * @param {string} address 地址
 * @param {number} allotId 分配的咨询师id
 * @param {number} channelId 一级渠道
 * @param {number} channelId2 二级渠道
 * @param {string} city 市
 * @param {string} cityId 市id
 * @param {string} county 区
 * @param {string} countyId 区id
 * @param {string} creatorMemberName 创建人
 * @param {string} descrition 描述
 * @param {number} grade 年级id
 * @param {string} isClue 线索 1
 * @param {string} province 省
 * @param {string} provinceId 省id
 * @param {number} schoolIntention 辅导意愿
 * @param {string} schoolName 学校名称
 * @param {number} sex 性别
 * @param {number} studentType 学员类型
 * @param {number} telLevel 电话级别二级
 * @param {string} weChatId 微信号
 * @param {number} id id
 * @returns {object}
 */
export const editCustomerInfo = (payload) => {
  if (payload?.referenceOaName) {
    payload.referenceOaName = payload.referenceOaName.trim();
  }
  return request.put(`/api/v1.0/app/${getAppId()}/app-customer/${payload?.id}`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取页面下拉列表  查询唯一元数据 线索 意向度 性别 年级
 * @param {string} name name sex grade clueStage intention telLevel studentType schoolIntention
 * @returns
 */
export const getMetaDatas = (payload) => {
  // /api/v1.0/app/{app-id}/meta-data
  return request.get(`/api/v1.0/app/${getAppId()}/meta-data`, {
    params: {
      region: 'customer',
      name: payload?.name,
    },
  });
};

// // 批量分配归属坐席
// export const batchAllocationAgents = (payload) => {
//   return request.put(`/api/v1.0/app/${getAppId()}/app-customer-belongs`, {
//     data: {
//       ...payload,
//     },
//   });
// };

/**
 * 转移坐席
 * @param {array} mcs 选中列表 {appMemberId: item?.appMemberId,appCustomerId: item?.appCustomerId}
 * @param {array} targetMemberIds 咨询师  [memberList[]item?.appMemberId]
 * @returns {object}
 */
export const batchAllocationCounselor = (payload) => {
  return request.put(`/api/v1.0/app/${getAppId()}/app-customer-transfers`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 转移坐席 v2.0
 */
export const batchAllocationCounselorV2 = (payload) => {
  return request.put(`/api/v2.0/app/${getAppId()}/app-customer-transfers`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 标签下拉
 * @returns {array}
 */
export const getSearchTagList = () => {
  return request.post(`/api/v1.0/app/${getAppId()}/tag`, {
    data: {
      pageIndex: 1,
      pageSize: 20,
    },
  });
};

/**
 * 线索下拉
 * @returns {array}
 */
export const getFirstClueList = async () => {
  return request.get(`/api/v1.0/app/${getAppId()}/channel/${0}`);
};

// export const getSecondClueList = (id) => {
//   return request.get(`/api/v1.0/app/${getAppId()}/channel/${id}`);
// };

/**
 * 获取导出excel模版
 * @param  {string}   ?meteDataRegion    元数据域  此次就是customer
 * @returns {url}
 */
export const downloadExcelTemplate = (meteDataRegion = 'customer') => {
  return request.get(`/api/v1.0/app/{app-id}/template/${meteDataRegion}/download`, {
    responseType: 'blob',
  });
};

// /**
//  * 获取异步任务分页集合(如批量导入线索、客户)
//  * @param  {number}   type       0线索导入 1客户导入 2线索导出  3客户导出
//  * @returns {records,...}
//  */
// export const getAsyncTasks = (payload = {}) => {
//   return request.post(`/api/v1.0/app/{app-id}/heracles-tasks`, {
//     data: {
//       current: payload?.pageIndex || 1,
//       size: payload?.pageSize || 100,
//       condition: { ...payload },
//     },
//   });
// };

/**
 * 提交异步任务(如批量导入线索、客户)
 * @param  {string}     description       任务描述
 * @param  {number}     appMemberId       操作人id
 * @param  {number}     type	            任务类型 0线索导入 1客户导入 2线索导出  3客户导出
 * @param  {object}     taskExecuteInfo   任务信息
 * @taskExecuteInfo
 * @param  {number}     channelId         渠道id
 * @param  {string}     region            元数据域 此次就是customer
 * @param  {number[]}   allotMemberIds    分配人id集合
 * @param  {string}     fileSourceUrl     导入文件的url
 * @returns {taskInfo}
 */
export const postAsyncTasks = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data,
  });
};

/**
 * 跟进提交
 * @param {string} action 'clue_follow'
 * @param {number} appCustomerId 线索id
 * @param {string} remark '跟进信息'
 * @param {string} when 当前跟进时间
 * @returns
 */
export const followBehaviours = (payload) => {
  return request.post(
    `/api/v1.0/app/${getAppId()}/app-customer/${payload?.appCustomerId}/behaviours`,
    {
      data: {
        appId: getAppId(),
        appMemberId: getStorage('appMemberId'),
        ...payload,
      },
    },
  );
};

/**
 * 跟进行为列表
 * 跟进记录
 * @param {string} action 'allot,clue_create,belong,clue_follow,clue_edit,call_record_follow'
 * @param {number} appCustomerId 线索id
 * @param {string} useCase 'clue'
 * @param {number} pageIndex
 * @param {number} pageSize
 * 通话
 * @param {string} action 'communicate_out'
 * @param {number} appCustomerId 线索id
 * @param {number} pageIndex
 * @param {number} pageSize
 * @returns
 */
export const getFollowBehaviours = (payload) => {
  return request.post(`/api/v1.0/app/${getAppId()}/behaviours`, {
    data: {
      // appMemberId:
      appId: getAppId(),
      ...payload,
    },
  });
};

// 根据appId查询所有分公司
export const getCompany = () => {
  return request.get(`/api/v1.0/app/${getAppId()}/app-corp`);
};

// 根据分公司id查询学校
export const getCampus = (deptId) => {
  return request.get(`/api/v1.0/app/${getAppId()}/${deptId}/schools`);
};

// 同步客户到ppts潜客
export const httpSyncCustome = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/student/${payload.customerId}/sync/${payload.allotSchoolId}`,
    {
      data: {
        // appMemberId:
        appId: getAppId(),
        ...payload,
      },
    },
  );
};

// 同步单个学员到ppts潜客
export const httpSyncStudent = (studentId) => {
  return request.put(`/api/v1.0/app/{app-id}/student/sync-ppts/${studentId}`);
};

/**
 *  根据NS编号查询缴费单
 * @param {stirng} ns NS编号
 * @returns
 */
export const getPayBills = (ns) => {
  return request.get(
    `/api/v1.0/app/{app-id}/app-member/${getStorage('appMemberId')}/pay-bills/ns/${ns}`,
  );
};

/**
 *  根据NS编号查询订购单
 * @param {stirng} ns NS编号
 * @returns
 */
export const getPurchaseBills = (ns) => {
  return request.get(
    `/api/v1.0/app/{app-id}/app-member/${getStorage('appMemberId')}/purchase-bills/ns/${ns}`,
  );
};
/**
 *  根据手机号查询历史通话记录
 * @param {stirng} phone 手机号
 * @returns
 */
export const getCallRecordHistories = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/call-record-histories/${payload.phone}`);
};

/**
 * 新增学员
 * @return
 */
export const addStudent = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/app-customer/${payload.appCustomerId}/app-preparatory-student`,
    {
      data: {
        ...payload,
      },
    },
  );
};

/**
 * 获取学员详情
 * @return
 */

export const getStudentDetail = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/app-student/search/${id}`);
};

/**
 * 编辑学员
 * @return
 */
export const editStudent = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/app-student/${payload.id}`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取学员列表
 * @returns
 */
export const getStudentsLists = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/app-students/app-customer-id/${payload.appCustomerId}`,
    {
      data: {
        ...payload,
      },
    },
  );
};

/**
 * 根据客户创建订单
 * @returns
 */
export const customerCreateOrder = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/app-customer/${payload.appCustomerId}/app-student/${payload.appStudentId}/order`,
    {
      data: {
        ...payload,
      },
    },
  );
};

/** 获取最近一次跟进位置
 * @param {object} 同线索查询参数
 */
export const getFollowLocation = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/app-member-customer/follow-position`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取渠道修改时间
 * @param {Object} payload
 * @param {Number} payload.corpId 分公司id
 */
export const getChannelDay = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/get-corp-channel-regulation`, {
    params: {
      ...payload,
    },
  });
};
