import { setStorage, getStorage } from '../../utils/filter';
import { getMemberRolePermissions } from '@/services/member';
import { getOceanPermission } from '@/services/highSea';

const appStore = {
  state: {
    permissions: JSON.parse(getStorage('permissions_resources')) || [],
    callCenterFlag: false,
    onOneline: false,
    seaPermission: {}, // 公海权限
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setCallCenteFlag(state, flag) {
      return { ...state, callCenterFlag: flag };
    },
    setOnOneline(state, flag) {
      return { ...state, onOneline: flag };
    },
  },
  effects: {
    getBtnPermission() {},
    async setPermissions(userId) {
      const res = await getMemberRolePermissions(userId);
      // Mock 权限和数据
      // const tempData = [
      //   {
      //     url: '/app',
      //   },
      //   {
      //     extValues: {},
      //     id: null,
      //     appId: null,
      //     code: '1',
      //     name: '成员列表',
      //     pid: null,
      //     status: null,
      //     remark: null,
      //     url: '/member/list',
      //     permissions: [
      //       {
      //         extValues: {},
      //         id: null,
      //         code: 'update',
      //         name: '更新',
      //         status: null,
      //         remark: null,
      //       },
      //     ],
      //   },
      //   {
      //     extValues: {},
      //     id: null,
      //     appId: null,
      //     code: '3',
      //     name: '角色管理',
      //     pid: null,
      //     status: null,
      //     remark: null,
      //     url: '/member/role',
      //     permissions: [
      //       {
      //         extValues: {},
      //         id: null,
      //         code: 'add',
      //         name: '新增',
      //         status: null,
      //         remark: null,
      //       },
      //       {
      //         extValues: {},
      //         id: null,
      //         code: 'update',
      //         name: '修改',
      //         status: null,
      //         remark: null,
      //       },
      //       {
      //         extValues: {},
      //         id: null,
      //         code: 'remove',
      //         name: '删除',
      //         status: null,
      //         remark: null,
      //       },
      //     ],
      //   },
      //   {
      //     extValues: {},
      //     id: null,
      //     appId: null,
      //     code: '2',
      //     name: '测试1',
      //     pid: null,
      //     status: null,
      //     remark: null,
      //     url: '/test/list',
      //     permissions: [],
      //   },
      // ];

      const permissions = res?.data?.resources || [];
      // permissions.unshift({'/app'});
      this.updateState({ permissions });
      setStorage('permissions_resources', JSON.stringify(permissions));
      return permissions || [];
    },
    async setSeaPermission() {
      const res = await getOceanPermission();
      this.updateState({ seaPermission: res?.data || {} });
    },
  },
};

export default appStore;
