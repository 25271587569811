import moment from 'moment';
import { updateAdClueRecordCallId } from '@/services/market';
import { message } from 'antd';
import { insertLogs } from '@/utils/db';

console.log('CallPanelStore');
const CallPanelStore = {
  state: {
    popScreenVisible: false,
    panelList: [],
    panelActiveKey: '',
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    increment(state, panelItem) {
      insertLogs({ event: 'reducer/increment', data: panelItem });
      return {
        panelList: [...state.panelList, panelItem],
      };
    },
  },
  effects: {
    // 编辑中手动打开弹屏
    openPopScreen({ model, from }, rootState) {
      const { panelList } = rootState.cdr;
      const curKey = `${model.id || model.callId}_${from}`;
      const curPanel = panelList.find((f) => f.key === curKey);

      insertLogs({
        event: 'handleOpenPopScreen',
        data: { curKey, from: from === 'cdr' ? '通话记录' : '投放数据' },
      });

      let panelKey = curPanel?.key;
      if (!curPanel) {
        const tabTag = {
          color: 'green',
          text: model.type === 0 ? '呼入' : '呼出',
        };
        if (from === 'fromAdDelivery') {
          tabTag.color = 'blue';
          tabTag.text = model.type === 2 ? '表单' : '易';
        }
        panelKey = this.createPanel({
          caller: {
            callId: model.callId, // 祥云sdk Rring事件中为纯小写callid
            direction: model.type === 0 ? 'in' : 'out',
            callPhone: model.callPhone,
            createDate: model.createDate,
          },
          tabTag,
          from,
          id: model.id,
        });
      }
      this.updateState({ panelActiveKey: panelKey });
      this.showPopScreen();
    },
    showPopScreen() {
      document.querySelector('.main-content').style.overflow = 'hidden';
      this.updateState({ popScreenVisible: true });
    },
    hidePopScreen(_, rootState) {
      if (rootState.cdr.popScreenVisible) {
        document.querySelector('.main-content').style.overflow = 'auto';
        this.updateState({ popScreenVisible: false });
      }
    },
    // 创建弹屏
    createPanel({ caller, from, tabTag, id }, rootState) {
      insertLogs({
        event: 'effects/createPanel',
        data: { caller, from, id },
      });
      const incoming = {
        callId: caller.callId,
        direction: caller.direction,
        callPhone: caller.dnis || caller.callPhone,
        channelCode: caller.phoneno,
        phoneno: caller.phoneno,
        createTime: moment(caller.ringdate).format('YYYY-MM-DD HH:mm:ss'),
      };
      const use = from === 'cdr' ? 'cdr' : 'fromAdDelivery';

      const key = `${id || incoming.callId}_${use}`;

      if (from === 'fromAdDelivery' && incoming.callId) {
        updateAdClueRecordCallId(id, incoming.callId).then((putRes) => {
          if (!putRes.success) message.warning('记录录音标识失败');
        });
      }

      if (rootState.cdr.panelList.some((s) => s.key === key)) {
        const curPanelIndex = rootState.cdr.panelList.findIndex((p) => p.key === key);
        const curPanel = rootState.cdr.panelList[curPanelIndex];
        curPanel.incoming = incoming;
        this.updateState({ panelList: [...rootState.cdr.panelList] });
        return key;
      }

      this.increment({
        id,
        use,
        label: moment(incoming.createTime).format('MM-DD HH:mm:ss'),
        tabTag,
        key,
        incoming,
      });
      return key;
    },
    removePanel({ key, use }, rootState) {
      const newList = rootState.cdr.panelList.filter((p) => p.key !== key);
      this.updateState({ panelList: newList });
      let activeKey = '';

      // const useList = newList.filter((f) => f.use === use);
      const useList = newList;
      if (useList.length) activeKey = useList[useList.length - 1].key;
      return activeKey;
    },
  },
};

export default CallPanelStore;
